@import '../Styles/theme.less';

.ant-divider-horizontal.ant-divider-with-text {
    border-top-color: rgb(180, 180, 180);

    .ant-divider-inner-text {
        h6 {
            color: rgb(117, 117, 117);
        }
    }
}

.button-container-group {
    display: flex;
    width: 100%;
    justify-content: center;
    button{
        margin-left: 10px;
        margin-right: 10px;
        height: 46px;
        padding: 4px 25px;
    }
}
