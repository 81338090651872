@import '../Styles/theme.less';

.icon-attachment {
  font-size: 2em;
  margin-top: 2px;
  padding-right: 5px !important;
}

.ant-image {
  margin-top: 5px;
}

.ant-image + span {
  display: block;
}