div#ant{
    .ant-select-arrow{
        color:red;
        top:47%;
    }
}

div#search > span {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
div#search:hover > span {
  visibility: visible;
  opacity: 1;
}

div#ant{
    width: 100%;
    .ant-collapse-arrow {
        padding-bottom: 0;
        padding-top: 2px;
        font-size: x-small;
    }

    .ant-collapse{
        border:none;
    }
    
    .ant-collapse-header{
        height: 25px;
        padding-bottom: 0;
        padding-top: 2px;
        font-size: small;

        // margin-top: 0;
        // margin-bottom: 0;
    }
    .ant-collapse-content{
        border-top:none;
    }
    .ant-collapse-content > .ant-collapse-content-box
    {
    
      padding: 0;
      margin:0;
      width: 100%;
    }
    .ant-tabs-nav{
        margin: 0;
    }
 
}