@import "~antd/dist/antd.less";
@import "Assets/Styles/theme.less";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.gen-form {
  box-sizing: border-box;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
}

.gen-form .gen-form-content {
  width: 700px;
  max-width: 700px;
}

.generic-container {
  padding-left: 25px;
  padding-right: 25px;
}

.crm-container {
  background: white;
}

.full-width {
  width: 100%;
}

.relative {
  position: relative;
}

/* Override for ant components valid globally */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: inherit;
  text-shadow: none;
  font-weight: bolder;
}

.ant-table-thead > tr > th {
  background-color: @table-head-background;
  color: @table-head-text-color;
  font-weight: bold;
}

.ant-drawer-header {
  height: 55px;
  background-color: @primary-color;
  border-radius: unset;

  .ant-drawer-title,
  .ant-drawer-close {
    color: @nav-text-color !important;
  }
}

button.ant-btn.ant-btn-primary.secondary {
  background-color: @nav-accent-color;
  border-color: @nav-accent-color;
}

.sidebar-dragger {
  width: 5px;
  padding: 4px 0 0;
  border-top: 1px solid transparent;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  position: absolute;
  cursor: ew-resize;
}

.sidebar-right {
  width: 5px;
  padding: 4px 0 0;
  border-top: 1px solid transparent;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: absolute;
  cursor: ew-resize;
}


.initials-avatar {
  background-color: @primary-variant-color;
  color: white;
  width: 33px;
  font-weight: bolder;
  transform: scale(1.3);
  margin-right: 5px;
}

.border-class{
  width: 1px;
}

.panel {    
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar {
    min-width: 200px;
    width: 400px;
    width:100%;
    box-sizing: border-box;
    text-align: center;
    flex-grow: 1;
}


.withMargin {
    margin: 10px;
    box-sizing: border-box;
}

.content {
    flex-grow: 2;  
    background: white; 
}


.container-class{
  width: 200px;
  min-width: 200px;
  max-width: 300px;
}

.container-class-list{
  width:300px;
  min-width: 294px;
  max-width: 600px;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #ff1865;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(247, 247, 247, 0.3);
}

.site-layout .site-layout-background {
  background: #f7f7f7;
}

.ant-layout-sider{
  background-color: transparent;
}

.ant-layout-sider-children {
  height: 100%;
  background: #f7f7f7;
  border-right: #ff1865;
  margin-top: -0.1px;
  padding-top: 0.1px;
}