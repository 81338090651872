@import '../Styles/theme.less';

.contact-header {
    display: flex;

    .avatar-circle-border {
        margin-right: 20px;
    }

    .contact-information {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            color: @nav-accent-color;
            font-weight: bold;
        }

        .icon-action-group {
            color: @text-color-success;
            display: flex;
            align-items: center;

            >* {
                margin-left: 0px;
                margin-right: 20px;
            }

            svg {
                font-size: 18px;
            }

            .las {
                font-size: 24px;
            }
        }
    }

}
