@import "../Styles/theme.less";

.banner-background {
  background-color: @banner-background;
}

.banner {
  background-color: @banner-background;
  width: 100%;
  // height: 54px;
  padding: 10px 0;
}
