@import "../Styles/theme.less";

.banner {
  display: flex;
  justify-content: space-between;

  .options {
    display: flex;
    align-items: center;

    button {
      background-color: @primary-color;
      color: white;
      border: none;
    }
  }
}

.contacts-title {
  display: flex;
  align-items: center;

  button {
    min-width: 200px;
    margin-right: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: bolder;
    }
  }

  .contacts-counter {
    background-color: @success-color;
    color: @text-color-success;
    height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
  }
}

.tabs-container {
  svg,
  .las {
    font-size: 15px;
  }
}

.contacts-dropdown {
  border: 0.1rem solid darkgray;
}

.ant-tabs-tab {
    padding: 7px 15px !important;
    margin: 0 !important;
  }

.ant-tabs-tab:hover {
  background-color: whitesmoke;
}

.ant-table-row.bold {
  font-weight: bold;
}