@import "../Styles/theme.less";

.ant-menu-horizontal {
  border: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color:@ant-menu-item-select-backgrouncolor;
}


.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after, 
.ant-menu-inline .ant-menu-item::after
{
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}


.ant-menu-inline 
.ant-menu-item-selected::after
{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 2px solid @primary-color;
  border-right: none;
  transform: scaleY(1);
  content: '';
  opacity: 1;
}

.crm-navbar {
  background-color: @primary-color;
  height: fit-content;
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  height: 54px;

  .crm-navbar-content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .crm-navbar-sec {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .navbar-logo {
    display: flex;
    align-items: center;
    height: auto;
    margin-right: 30px;

    h1 {
      color: @nav-text-color;
      font-weight: bolder;
      padding: 0;
      margin: 0;
    }
  }

  .crm-navbar-menu {
    height: auto;
    margin: 0;
    background-color: @primary-color;
    color: @nav-text-color;

    display: flex;
    align-items: center;

    .ant-dropdown-link {
      color: @nav-text-color;
    }

    li.ant-menu-item {
      padding-left: 20px;
      padding-right: 20px;
      margin: 0;
      border-bottom-width: 4px;
      color: @nav-text-color !important;

      a:hover {
        color: @nav-text-color;
      }
    }

    li.ant-menu-item-selected {
      border-color: @nav-accent-color;
      background: @primary-variant-color;
      color: @nav-text-color;
    }

    li.ant-menu-item:hover {
      border-color: @nav-accent-color;
      background: @primary-variant-color;
      color: @nav-text-color;
      border-bottom-width: 4px;
    }
  }

  .nav-user-dropdown {
    svg {
      font-size: 10px;
    }
  }
}


.ant-list-vertical .ant-list-item-action{
  margin-top: 0px;
  box-shadow: none;
  margin-left: "none";
}

.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}

.main_div {
  height: 100%;
  width:100%;
  border-collapse: collapse;
}

.ant-card-rtl .ant-card-grid {
  float: right;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  background-color: none;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 32px;
}
.ant-layout-header-chatroom{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0;
  padding-left: 12px;
  height: 40px;
  background-color: transparent;
  color:red;
  text-overflow: ellipsis;
  float: right;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin-top: 0;
}

.nav-user-menu {
  li {
    min-width: 250px;
  }

  .user-info {
    p {
      margin: 0;
    }

    img {
      margin-right: 10px;
      max-width: 40px;
      object-fit: contain;
    }
  }

  .bottom-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.nav-user-dropdown {
  color: @nav-text-color;

  img {
    margin-right: 10px;
  }
}

.message-box {
  background: @primary-color;
  float: right;
  color: @nav-text-color;
  margin-right: 6px;
  padding: 2px 6px;
  border-radius: 6px 6px 6px 6px;
  margin-top: 3px;
  margin-bottom: 3px;
  clear: right;

  a {
    color: @nav-text-color;
  }
}

.message-partner {
  background: @background-base-color;
  float: left;
  color: @table-head-text-color;
  margin-left: 6px;
  padding: 2px 6px;
  border-radius: 6px 6px 6px 6px;
  margin-top: 3px;
  margin-bottom: 3px;
  clear: left;
}

.note-partner {
  background: @warning-color;
  float: right;
  color: @nav-text-color;
  margin-right: 6px;
  padding: 2px 6px;
  border-radius: 6px 6px 6px 6px;
  margin-top: 3px;
  margin-bottom: 3px;
  clear: right;
}

.footerHourRight{
  float: right;
  color: "#aaa";
  clear: right;
  border: none;
  margin-right: 6px;
  // padding-right:10px;
  font-size: 11px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -4px;
  margin-bottom: 0px;
}

.footerHourLeft{
  float: left;
  clear: left;
  color: "#aaa";
  border: none;
  // padding-left: 10px;
  margin-left: 6px;
  font-size: 11px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -4px;
  margin-bottom: 0px;
}

.ant-collapse-content > .ant-collapse-content-box
{
  padding: 0;

}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background:#383c4052;
  padding-top:0;
  padding-bottom:0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
  padding-top:0;
  padding-bottom:0;
}

.ant-layout-footer{
  background: white;
}