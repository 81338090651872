@import '../Styles/theme.less';

.ant-card {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.30);
    border: 1px solid rgb(147,147,147);
    border-radius: 7px;
    margin-top: 25px;
    margin-bottom: 25px;
    
    .ant-card-head {
        border-bottom: none;
        padding: 0 12px;

        .ant-card-head {
            padding: 5px 0;
        }

        .ant-card-extra {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .ant-card-head-title {
        padding: 0;
        font-size: 14px;

        .icon {
            color: @nav-accent-color;
        }

        .title{
            margin-left: 15px;
        }
        
        p {
            margin-bottom: 0;
        }
    }


    .ant-card-body {
        padding-top: 0;
        padding-bottom: 0;
    }
}